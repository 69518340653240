import React from 'react';
import { NavLink } from 'react-router-dom';
import CreditLogoIcon from '../../assets/icons/CreditLogoIcon';
import './Footer.css';


const Footer = () => {
  return (
    <footer className="footer" role="contentinfo">
            <div className='footer-wrapper'>
                <div className='footer-content-wrapper'>
                    <div className='footer-info-section-wrapper'>
                        <div className='footer-info-section-container'>
                            <div className='footer-info-section'>
                                <div className='footer-info'>
                                    <div className='footer-columns-wrapper'>
                                        <div className='footer-column'>
                                            <div className='footer-column-section-header'>
                                                <h6><span>BROWSE OUR SITE</span></h6>
                                            </div>
                                            <nav className='footer-nav'>
                                            <ul className='footer-nav-list'>
                                                <li className='footer-navlink-wrapper'>
                                                    <NavLink
                                                    to='/'
                                                    className='footer-navlink'
                                                    activeclassname='active'
                                                    onClick={() => window.scrollTo(0, 0)} // Scroll to top on click
                                                    >
                                                    <span>Home</span>
                                                    </NavLink>
                                                </li>
                                                <li className='footer-navlink-wrapper'>
                                                    <NavLink
                                                    to='/seamless-gutters'
                                                    className='footer-navlink'
                                                    activeclassname='active'
                                                    onClick={() => window.scrollTo(0, 0)} // Scroll to top on click
                                                    >
                                                    <span>Seamless Gutters</span>
                                                    </NavLink>
                                                </li>
                                                <li className='footer-navlink-wrapper'>
                                                    <NavLink
                                                    to='/gutter-guards'
                                                    className='footer-navlink'
                                                    onClick={() => window.scrollTo(0, 0)} // Scroll to top on click
                                                    >
                                                    <span>Gutter Guards</span>
                                                    </NavLink>
                                                </li>
                                                <li className='footer-navlink-wrapper'>
                                                    <NavLink
                                                    to='/testimonials'
                                                    className='footer-navlink'
                                                    onClick={() => window.scrollTo(0, 0)} // Scroll to top on click
                                                    >
                                                    <span>Our Reviews</span>
                                                    </NavLink>
                                                </li>
                                                <li className='footer-navlink-wrapper'>
                                                    <NavLink
                                                    to='/gallery'
                                                    className='footer-navlink'
                                                    onClick={() => window.scrollTo(0, 0)} // Scroll to top on click
                                                    >
                                                    <span>Gallery</span>
                                                    </NavLink>
                                                </li>
                                                <li className='footer-navlink-wrapper'>
                                                    <NavLink
                                                    to='/contact'
                                                    className='footer-navlink'
                                                    onClick={() => window.scrollTo(0, 0)} // Scroll to top on click
                                                    >
                                                    <span>Contact</span>
                                                    </NavLink>
                                                </li>
                                                <li className='footer-navlink-wrapper'>
                                                    <NavLink
                                                    to='/the-villages'
                                                    className='footer-navlink'
                                                    onClick={() => window.scrollTo(0, 0)} // Scroll to top on click
                                                    >
                                                    <span>The Villages</span>
                                                    </NavLink>
                                                </li>
                                                <li className='footer-navlink-wrapper'>
                                                    <NavLink
                                                    to='/service-areas'
                                                    className='footer-navlink'
                                                    onClick={() => window.scrollTo(0, 0)} // Scroll to top on click
                                                    >
                                                    <span>Service Areas</span>
                                                    </NavLink>
                                                </li>
                                            </ul>

                                            </nav>
                                        </div>
                                        <div className='footer-column'>
                                            <div className='footer-column-section-header'>
                                                <h6><span>CONTACT INFORMATION</span></h6>
                                            </div>
                                            <div className='footer-contact-method-wrapper'>
                                                <p><span>Phone: <a href='tel:352-812-0831' aria-label="Call Watkins Seamless Gutters">352-812-0831</a></span></p>
                                            </div>
                                            <div className='footer-contact-method-wrapper'>
                                                <p><span>Email: <a href='mailto:GuttersByWatkins@gmail.com' aria-label="Email Watkins Seamless Gutters">GuttersByWatkins@gmail.com</a></span></p>
                                            </div>
                                            <div className="socials-row">
                                                <div className="socials-wrapper">
                                                    <a href="https://www.facebook.com/GuttersByWatkins/" className="social-icon facebook" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                                                    <i className="fa-brands fa-facebook-f" />
                                                    </a>
                                                    <a href="https://goo.gl/maps/i9dm81CxUhcgBvuf8" className="social-icon google" aria-label="Google" target="_blank" rel="noopener noreferrer">
                                                    <i className="fa-brands fa-google" />
                                                    </a>
                                                    <a href="https://www.yelp.com/biz/watkins-seamless-gutters-summerfield-2" className="social-icon yelp" aria-label="Yelp" target="_blank" rel="noopener noreferrer">
                                                    <i className="fa-brands fa-yelp" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='footer-column-section-header'>
                                                <h6><span>BUSINESS HOURS</span></h6>
                                            </div>
                                            <div className='footer-hours-wrapper'>
                                                <dl>
                                                    <div className='hours-item-wrapper'>
                                                        <dt day='0' aria-label="Monday to Friday">Mon - Fri</dt>
                                                        <dd>
                                                            <time>8:00 am </time>
                                                            -
                                                            <time> 4:00 pm</time>
                                                        </dd>
                                                    </div>
                                                    <div className='hours-item-wrapper'>
                                                        <dt day='5'>Sat - Sun</dt>
                                                        <dd>Closed</dd>
                                                    </div>
                                                </dl>
                                            </div>
                                            <div className='footer-text-wrapper'>
                                                <p><span>By appointment only</span></p>
                                            </div>
                                            <div className='footer-text-wrapper'>
                                                <p><span>Public holidays: Closed</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='footer-legal-section-wrapper'>
                        <div className='footer-legal-section-container'>

                            <div className='footer-legal-content-wrapper'>
                                <div className='footer-legal-content' aria-label="Legal and copyright notice">
                                    All content on this website, unless otherwise stated, including images, is protected by copyright law. Unauthorized downloading, reproduction, or distribution of any material from this site is strictly prohibited.
                                </div>
                            </div>
                            <div className='footer-credit'>
                                <p>Website built and managed by &nbsp;<a href="https://www.wattswebworks.com" target="_blank" rel="noopener noreferrer" aria-label="WattsWebWorks, opens in a new tab">WattsWebWorks<CreditLogoIcon width='35px' height='35px' style={{marginBottom: '8px'}}/></a></p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    </footer>
  );
};

export default Footer;
