import React, { useState, useRef } from 'react';
import './Hero.css';
import ReCAPTCHA from 'react-google-recaptcha';

const Hero = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    projectNeeds: '',
    additionalInfo: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(''); // Store reCAPTCHA token
  const recaptchaRef = useRef(null); // Reference for reCAPTCHA

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName || formData.firstName.length > 100) {
      newErrors.firstName = 'First name is required and should be less than 100 characters.';
    }
    if (!formData.lastName || formData.lastName.length > 100) {
      newErrors.lastName = 'Last name is required and should be less than 100 characters.';
    }
    if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Valid email is required.';
    }
    if (!formData.phone || !/^\d{10,11}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number is required and should be 10-11 digits.';
    }
    if (!formData.address || formData.address.length > 250) {
      newErrors.address = 'Address is required and should be less than 250 characters.';
    }
    if (!formData.projectNeeds || formData.projectNeeds.length > 8000) {
      newErrors.projectNeeds = 'Project needs are required and should be less than 8000 characters.';
    }
    if (formData.additionalInfo.length > 8000) {
      newErrors.additionalInfo = 'Additional info should be less than 8000 characters.';
    }
    return newErrors;
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA verification.');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/new-inquiry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          recaptchaToken, // Send the reCAPTCHA token with the form data
        }),
      });

      if (response.ok) {
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address: '',
          projectNeeds: '',
          additionalInfo: '',
        });
        setErrors({}); // Clear errors on successful submission
        alert('Inquiry sent successfully!');
        recaptchaRef.current.reset(); // Reset reCAPTCHA after submission
        setRecaptchaToken(''); // Clear the token
      } else {
        const errorData = await response.json();
        alert('Error: ' + errorData.message);
      }
    } catch (err) {
      console.error('Error submitting the form:', err);
      alert('Failed to send the inquiry. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle reCAPTCHA change
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token); // Store the reCAPTCHA token
  };

  return (
    <div className='hero-wrapper'>
      <div className='hero-content-wrapper'>
        <div className='hero-content'>
          <div className='free-estimate'>
            <div className='estimate-header'>
              <p><span>Free No-Obligation Estimates</span></p>
            </div>
            <div className='estimate-button-wrapper'>
              <button className='estimate-button'><span className="text">Call Us Today!</span></button>
            </div>
          </div>
          <div className='form-background'>
            <div className='form-container'>
              <div className='form-content-wrapper'>
                <form className='estimate-form' onSubmit={handleSubmit}>
                  <div className='contact-form-input-wrapper half'>
                    <label htmlFor="firstName">First Name</label>
                    <input 
                      type="text" 
                      id="firstName" 
                      name="firstName" 
                      value={formData.firstName} 
                      onChange={handleChange}
                      maxLength={100}
                      required 
                    />
                    {errors.firstName && <div className="error-message">{errors.firstName}</div>}
                  </div>

                  <div className='contact-form-input-wrapper half'>
                    <label htmlFor="lastName">Last Name</label>
                    <input 
                      type="text" 
                      id="lastName" 
                      name="lastName" 
                      value={formData.lastName} 
                      onChange={handleChange}
                      maxLength={100}
                      required 
                    />
                    {errors.lastName && <div className="error-message">{errors.lastName}</div>}
                  </div>

                  <div className='contact-form-input-wrapper half'>
                    <label htmlFor="email">Email</label>
                    <input 
                      type="email" 
                      id="email" 
                      name="email" 
                      value={formData.email} 
                      onChange={handleChange}
                      maxLength={150}
                      required 
                    />
                    {errors.email && <div className="error-message">{errors.email}</div>}
                  </div>

                  <div className='contact-form-input-wrapper half'>
                    <label htmlFor="phone">Phone</label>
                    <input 
                      type="tel" 
                      id="phone" 
                      name="phone" 
                      value={formData.phone} 
                      onChange={handleChange}
                      inputMode="numeric"
                      maxLength={11}
                      pattern="\d{10,11}"
                      title="Phone number should be 10-11 digits"
                      required
                    />
                    {errors.phone && <div className="error-message">{errors.phone}</div>}
                  </div>

                  <div className='contact-form-input-wrapper full'>
                    <label htmlFor="address">Address</label>
                    <input 
                      type="text" 
                      id="address" 
                      name="address" 
                      value={formData.address} 
                      onChange={handleChange}
                      maxLength={250}
                      required 
                    />
                    {errors.address && <div className="error-message">{errors.address}</div>}
                  </div>

                  <div className='contact-form-input-wrapper full'>
                    <label htmlFor="projectNeeds">Project Needs</label>
                    <input 
                      type="text" 
                      id="projectNeeds" 
                      name="projectNeeds" 
                      value={formData.projectNeeds} 
                      onChange={handleChange}
                      maxLength={8000} 
                      required 
                    />
                    {errors.projectNeeds && <div className="error-message">{errors.projectNeeds}</div>}
                  </div>

                  <div className='contact-form-input-wrapper full'>
                    <label htmlFor="additionalInfo">Additional Information</label>
                    <textarea 
                      id="additionalInfo" 
                      name="additionalInfo" 
                      value={formData.additionalInfo} 
                      onChange={handleChange}
                      maxLength={8000} 
                    />
                    {errors.additionalInfo && <div className="error-message">{errors.additionalInfo}</div>}
                  </div>

                  <div className='captcha-wrapper'>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      onChange={handleRecaptchaChange}
                      ref={recaptchaRef}
                    />
                  </div>

                  <div className='form-submit-wrapper'>
                    <button type='submit' className='estimate-button' disabled={isSubmitting || !recaptchaToken}>
                      {isSubmitting ? 'Sending...' : 'Send Message'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
