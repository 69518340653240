import React, { useState, useEffect } from 'react';
import './ContactModal.css';
import NavLogo from './../../assets/images/tinyLogo.jpg';
import { HashLink } from 'react-router-hash-link';

const ContactModal = ({ onClose, isModalOpen }) => {
    const [isOptionsVisible, setOptionsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 785);

    // Update isMobile state when resizing window
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 785);
        };
      
        const debouncedHandleResize = debounce(handleResize, 250); // Debounce by 250ms
      
        window.addEventListener('resize', debouncedHandleResize);
      
        return () => window.removeEventListener('resize', debouncedHandleResize);
      }, []);

      function debounce(func, wait) {
        let timeout;
        return function (...args) {
          const later = () => {
            clearTimeout(timeout);
            func(...args);
          };
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
        };
      }      
      

    // Custom scroll handler to adjust the scroll position
    const scrollWithOffset = (el) => {
        const yOffset = isMobile ? -200 : -450; // Adjust this value based on your needs for mobile
        const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    };

    // Toggle contact options visibility on click or Enter key press for all screen sizes
    const toggleOptions = () => {
        setOptionsVisible(!isOptionsVisible);
    };

    // Handle Enter key press for options
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            toggleOptions();
        }
    };

    // Handle Enter key press for the close button
    const handleCloseKeyDown = (e) => {
        if (e.key === 'Enter') {
            onClose();
        }
    };

    // Handle mouse events for larger screens (hover behavior)
    const handleMouseEnter = () => {
        if (!isMobile) {
            setOptionsVisible(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isMobile) {
            setOptionsVisible(false);
        }
    };

    return (
        <div className={`contact-modal-position-wrapper ${isModalOpen ? 'modal-open' : 'modal-closed'}`}>
            <div className='contact-modal-wrapper'>
                <div className='contact-modal-container'>
                    <div className='contact-modal'>
                        <div 
                            className='contact-modal-close-button' 
                            onClick={onClose} 
                            onKeyDown={handleCloseKeyDown} // Handle Enter key for closing the modal
                            aria-label="Close contact modal" 
                            tabIndex={isModalOpen ? 0 : -1} // Dynamic tabIndex for close button
                        >
                            X
                        </div>
                        <div className='contact-modal-header'>
                            <div className='contact-modal-logo'>
                                <img src={NavLogo} alt='Watkins Seamless Gutters Logo' />
                            </div>
                            <div className='contact-modal-title'>Let's Talk!</div>
                        </div>
                        <div className='contact-modal-content'>
                            Thanks for stopping by! We're here to help, please don't hesitate to reach out.
                            <br />
                        </div>
                        <div className='contact-modal-contact-buttons'>
                            <div 
                                className='contact-options-toggle' 
                                onClick={toggleOptions} 
                                onKeyDown={handleKeyDown} // Handle Enter key
                                onMouseEnter={handleMouseEnter} // Show menu on hover for large screens
                                onMouseLeave={handleMouseLeave} // Hide menu on hover leave for large screens
                                tabIndex={isModalOpen ? 0 : -1} // Apply tabindex conditionally
                                role="button" // Accessibility: indicate it's a button
                                aria-expanded={isOptionsVisible} // Indicate if options are expanded
                                aria-controls="contact-options" // Associate with the options menu
                            >
                                <i className="fa-solid fa-bars" />
                                <div id="contact-options" className={`contact-options ${isOptionsVisible ? 'visible' : ''}`}>
                                    <a 
                                        href='tel:352-812-0831' 
                                        tabIndex={isOptionsVisible ? 0 : -1} // Tab only if options are visible
                                    >
                                        <i className="fa-solid fa-phone" /> Click-to-call 352-812-0831
                                    </a>
                                    <a 
                                        href='mailto:GuttersByWatkins@gmail.com'
                                        tabIndex={isOptionsVisible ? 0 : -1} // Tab only if options are visible
                                    >
                                        <i className="fa-regular fa-envelope"/> Email GuttersByWatkins@gmail.com
                                    </a>

                                    {/* Conditionally apply custom scroll for mobile */}
                                    <HashLink 
                                        to='/contact#contact-form'
                                        tabIndex={isOptionsVisible ? 0 : -1}
                                        scroll={scrollWithOffset} // Always use the custom scroll function
                                    >
                                        <i className="fa-regular fa-message"/> Send us a message
                                    </HashLink>
                                </div>
                            </div>
                            <a 
                                href='tel:352-812-0831' 
                                className='contact-modal-contact-button'
                                tabIndex={isModalOpen ? 0 : -1} // Apply tabindex conditionally
                            >
                                Call 352-812-0831
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactModal;