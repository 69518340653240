import React from 'react';
import { Link } from 'react-router-dom';
import './TopRated.css';

const TopRated = () => {

  return (
    <div className='top-rated-section-wrapper'>
        <div className='top-rated-content-wrapper'>
            <div className='top-rated-header'>
                <h2>Top-Rated</h2>
            </div>

            <div className='top-rated-content'>
                <p>
                    <em>Gutters By Watkins </em>is one of the leading seamless gutter vendors in the region with a 5-star User Satisfaction Rating, over 20+ years of experience, and the highest dedication to quality possible. Our motto “Exceeding Expectations… Not Budgets” assures you of peace-of-mind and makes your vendor choice easy.
                    <br/>
                    <br/>
                    Is your home/building protected by <em> Gutters By Watkins? </em>Shouldn't it be?
                    <br/>
                    <br/>
                    To see reviews from our many satisfied customers or to post your own comments on our service, visit our Facebook site <a href='https://www.facebook.com/GuttersByWatkins' target='_blank' rel="noopener noreferrer">here</a>.
                    <br/>
                    <br/>
                    Click <Link to='/testimonials'>here</Link> to Learn More.
                </p>
            </div>
        </div>
    </div>
  );
};

export default TopRated;
