import React from 'react';
import './MobileContactBar.css';

const MobileContactBar = ({ toggleContactMenu }) => {
  return (
    <div className='mobile-contact-bar-wrapper'>
      <div className='mobile-contact-bar'>
        <div className='contact-menu-toggle'>
          <button onClick={toggleContactMenu}>
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
        <div className='contact-menu-toggle'>
          <a href="tel:352-812-0831"><i className="fa-solid fa-phone"></i></a>
        </div>
      </div>
    </div>
  );
};

export default MobileContactBar;
