import './App.css';
import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import MobileNavBar from './components/NavBar/MobileNavBar';
import ContactBar from './components/ContactBar/ContactBar';
import Contact from './pages/Contact/Contact';
import Gallery from './pages/Gallery/Gallery';
import GutterGuards from './pages/GutterGuards/GutterGuards';
import Home from './pages/Home/Home';
import OurReviews from './pages/OurReviews/OurReviews';
import SeamlessGutters from './pages/SeamlessGutters/SeamlessGutters';
import ServiceAreas from './pages/ServiceAreas/ServiceAreas';
import TheVillages from './pages/TheVillages/TheVillages';
import Footer from './components/Footer/Footer';
import PersistentContactButton from './components/PersistentContactButton/PersistentContactButton';
import ContactModal from './components/ContactModal/ContactModal';
import MobileContactBar from './components/ContactBar/MobileContactBar';
import MobileContactMenu from './components/MobileContactMenu/MobileContactMenu'; // Import MobileContactMenu
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';

import heroBG from './assets/images/hero.webp';
import roofMan from './assets/images/gutterClean.webp';

import gallery1 from './assets/images/gallery/gallery1.webp';
import gallery2 from './assets/images/gallery/gallery2.webp';
import gallery3 from './assets/images/gallery/gallery3.webp';
import gallery4 from './assets/images/gallery/gallery4.webp';
import gallery5 from './assets/images/gallery/gallery5.webp';
import gallery6 from './assets/images/gallery/gallery6.webp';
import gallery7 from './assets/images/gallery/gallery7.webp';
import gallery8 from './assets/images/gallery/gallery8.webp';



function App() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false); // Add state for MobileContactMenu visibility
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const imagesToPreload = [
      heroBG,
      roofMan,
      gallery1,
      gallery2,
      gallery3,
      gallery4,
      gallery5,
      gallery6,
      gallery7,
      gallery8
    ];
    imagesToPreload.forEach((imageSrc) => {
      const img = new Image();
      img.src = imageSrc;
    });
  }, []);

  // Function to toggle the modal
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  // Function to toggle the mobile contact menu
  const toggleContactMenu = () => {
    setMenuOpen(!isMenuOpen); // Toggle visibility
  };

  // Use useEffect to listen for window resizing and update the width
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setModalOpen(false);  // Close the modal on Escape key press
      }
    };
  
    // Attach event listener for keydown
    document.addEventListener('keydown', handleKeyDown);
  
    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);  

  return (
    <div className="App">
      <header role="banner">

        {/* Conditionally render the ContactBar only for desktop screens */}
        {windowWidth >= 785 && <ContactBar />}

        {/* Conditionally render the NavBar or MobileNavBar based on screen width */}
        {windowWidth >= 785 ? <NavBar /> : <MobileNavBar />}
      </header>

      {/* Conditionally render MobileContactBar only on mobile and when menu is not open */}
      {windowWidth < 785 && !isMenuOpen && <MobileContactBar toggleContactMenu={toggleContactMenu} />}
      
      {/* Conditionally render MobileContactMenu */}
      <MobileContactMenu isMenuOpen={isMenuOpen} toggleContactMenu={toggleContactMenu} />

      {/* The button only appears when the modal is closed */}
      {!isModalOpen && !isMenuOpen && <PersistentContactButton onClick={toggleModal} isModalOpen={isModalOpen} />}

      {/* The modal only appears when it's open */}
      {isModalOpen && <ContactModal onClose={toggleModal} isModalOpen={isModalOpen} />}


    
      <main id="main-content" className="content-wrapper">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/seamless-gutters" element={<SeamlessGutters />} />
          <Route path="/gutter-guards" element={<GutterGuards />} />
          <Route path="/testimonials" element={<OurReviews />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/the-villages" element={<TheVillages />} />
          <Route path="/service-areas" element={<ServiceAreas />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>



      <Footer />
    </div>
  );
}

export default App;
