import React from 'react';

const CreditLogoIcon = ({ width = '50px', height = '50px', style = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="190 210 900 600"
      width={width}
      height={height}
      style={style}
      aria-label="WattsWebWorks logo"
    >
      <style type="text/css">
        {`.st0{fill:#022852;} .st1{fill:#E4A906;}`}
      </style>
         <g>
            <g xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path className="st0" d="M747.12,839.31c3.57-12.19,0.77-30.64-16.18-30.23c0,0-164.6,0-179.42,0c-0.71,0-1.42,0.02-2.13,0.09    c-16.41,1.79-20.66,19.55-15.99,31.92c2.69,7.12,4.11,13.8,0.3,22.22c-5.42,11.98-1.04,31.41,16.76,31.41c0.04,0,8.35,0,21.39,0    l2.5,21.21c1.2,10.18,9.83,17.86,20.09,17.86h88.74c9.55,0,17.8-6.68,19.78-16.02l4.89-23.04c13.81,0,22.69,0,22.69,0    c7.85,0,13.86-4.82,16.37-12.12c0.51-1.49,0.85-2.94,1.04-4.36c0.98-7.25-1.71-13.7-2.68-21.23    C744.5,851.02,745.43,845.07,747.12,839.31z"/>
                    <g>
                        <path className="st0" d="M640.01,276.68c-80.37,0-149.26,48.98-178.32,118.64h20.34c21.05-44.69,60.6-78.99,108.81-93.08     c9.73-2.85,19.81-4.87,30.16-5.99c3.3-0.36,6.63-0.62,9.98-0.79c2.99-0.15,6-0.23,9.03-0.23c3.03,0,6.04,0.08,9.03,0.23     c2.7,0.14,5.4,0.34,8.07,0.6c10.3,1,20.33,2.89,30.03,5.6c49.13,13.71,89.5,48.35,110.84,93.66h20.35     C789.27,325.66,720.36,276.68,640.01,276.68z M801.34,535.57c-19.86,48.04-60.66,85.27-111.06,100.37     c-8.72,2.62-17.73,4.57-26.97,5.79c-4.71,0.64-9.47,1.08-14.28,1.32c-2.99,0.15-6,0.23-9.03,0.23c-3.03,0-6.04-0.08-9.03-0.23     c-5.47-0.28-10.87-0.81-16.2-1.58c-9.28-1.34-18.33-3.41-27.07-6.14c-49.47-15.49-89.45-52.36-109.05-99.76H458.7     c27.1,73.69,98.05,126.26,181.31,126.26s154.21-52.57,181.3-126.26H801.34z"/>
                        <path className="st0" d="M712.4,338.79c-7.39-15.14-15.9-27.6-25.26-37.13c-14.34-14.62-30.65-22.36-47.87-22.36     c-17.44,0-33.95,7.94-48.43,22.94c-9.13,9.45-17.46,21.71-24.69,36.55c-8.11,16.63-14.51,35.78-19.01,56.53h13.35     c11.32-49.91,33.61-87.26,60.51-99.07c3.26-1.43,6.59-2.49,9.98-3.14c2.73-0.54,5.5-0.81,8.3-0.81c3.3,0,6.55,0.38,9.76,1.11     c2.73,0.63,5.42,1.51,8.07,2.65c27.1,11.59,49.56,49.07,60.96,99.26h13.34C726.9,374.57,720.51,355.42,712.4,338.79z      M720.16,535.57c-9.8,51.2-30.82,90.83-56.85,106.16c-4.61,2.73-9.39,4.69-14.28,5.81c-3.21,0.73-6.46,1.11-9.76,1.11     c-2.8,0-5.57-0.27-8.3-0.81c-5.57-1.07-10.99-3.23-16.2-6.37c-25.82-15.53-46.66-55-56.4-105.9h-13.25     c4.52,24.65,11.62,47.31,21.02,66.59c6.4,13.13,13.66,24.24,21.56,33.17c15.21,17.18,32.85,26.32,51.56,26.32     c18.49,0,35.93-8.93,51.02-25.71c8.12-9.03,15.56-20.35,22.11-33.78c9.4-19.28,16.5-41.94,21.01-66.59H720.16z"/>
                        <g>
                            <path className="st0" d="M639.97,380.99c-22.88,0-43.2-1.27-60.19-2.98c-52.7-5.31-84.86-15.61-86.21-16.04l3.39-10.46c0,0,0,0,0,0      l0,0c0.43,0.14,32.72,10.44,84.26,15.6c47.58,4.76,121.79,5.99,205.08-15.21l2.71,10.66      C733.78,376.62,682.45,380.99,639.97,380.99z"/>
                        </g>
                        <g>
                            <path className="st0" d="M790.61,579.75c-81.15-22.22-159.29-21.03-210.55-16.12c-55.52,5.32-92.15,15.99-92.51,16.09l-3.11-10.55      c1.53-0.45,38.01-11.07,94.57-16.49c52.18-5,131.75-6.2,214.5,16.46L790.61,579.75z"/>
                        </g>
                        <rect x="632.34" y="289.37" className="st0" width="14.23" height="105.95"/>
                        <rect x="632.89" y="535.57" className="st0" width="14.78" height="115.03"/>
                        <path className="st0" d="M802.31,572.01c0,0-12.69,11.72-27.82,25.38c-15.13,13.67-61.02,59.07-154.74,50.77     s-141.07-75.17-141.07-75.17s51.75,89.44,51.97,183.35c0.02,10.66,8.64,19.3,19.3,19.3h179.38c10.86,0,19.59-8.95,19.3-19.81     C747.71,721.59,752.69,647.69,802.31,572.01z M704.81,718.56c-0.55,6.83-6.25,12.08-13.1,12.08H586.79     c-6.88,0-12.58-5.3-13.11-12.17c-1.27-16.44-4.62-40.82-22.02-80.26c0,0,45.96,17.7,88.34,16.17     c42.38-1.53,80.17-12.6,80.17-12.6C710.03,669.42,706.3,700.15,704.81,718.56z"/>
                    </g>
                    <g>
                        <path className="st1" d="M399.06,428.05h22.75c2.84,0,5.35,1.84,6.2,4.55l17.28,57.34L463.32,434c1.05-3.27,4.1-5.49,7.53-5.49h15.72     c3.48,0,6.55,2.27,7.57,5.6l17.72,55.83l17.72-57.47c0.86-2.69,3.36-4.52,6.19-4.52h24.76l-30.4,85.2     c-1.46,4.15-5.39,6.93-9.79,6.93h-14.92c-4.52,0-8.52-2.93-9.89-7.24l-15.51-48.82l-16.86,49.63c-1.43,4.21-5.38,7.04-9.83,7.04     h-13.35c-4.37,0-8.27-2.73-9.76-6.84L399.06,428.05z"/>
                        <path className="st1" d="M559.09,428.05h22.75c2.84,0,5.35,1.84,6.2,4.55l17.28,57.34L623.36,434c1.05-3.27,4.1-5.49,7.53-5.49h15.72     c3.48,0,6.55,2.27,7.57,5.6l17.72,55.83l17.72-57.47c0.86-2.69,3.36-4.52,6.19-4.52h24.76l-30.4,85.2     c-1.46,4.15-5.39,6.93-9.79,6.93h-14.92c-4.52,0-8.52-2.93-9.89-7.24l-15.51-48.82l-16.86,49.63c-1.43,4.21-5.38,7.04-9.83,7.04     h-13.35c-4.37,0-8.27-2.73-9.76-6.84L559.09,428.05z"/>
                        <path className="st1" d="M719.47,428.05h22.75c2.84,0,5.35,1.84,6.2,4.55l17.28,57.34L783.73,434c1.05-3.27,4.1-5.49,7.53-5.49h15.72     c3.48,0,6.55,2.27,7.57,5.6l17.72,55.83l17.72-57.47c0.86-2.69,3.36-4.52,6.19-4.52h24.76l-30.4,85.2     c-1.46,4.15-5.39,6.93-9.79,6.93h-14.92c-4.52,0-8.52-2.93-9.89-7.24l-15.51-48.82l-16.86,49.63c-1.43,4.21-5.38,7.04-9.83,7.04     h-13.35c-4.37,0-8.27-2.73-9.76-6.84L719.47,428.05z"/>
                    </g>
                </g>
                <g>
                    <path className="st1" d="M640.58,90.21L640.58,90.21c-8.94,0-16.08,7.46-15.69,16.39l4.9,111.5c0.21,5.82,4.99,10.44,10.82,10.44    c5.8,0,10.57-4.57,10.82-10.36l4.84-111.59C656.65,97.66,649.52,90.21,640.58,90.21z"/>
                    <path className="st1" d="M365.22,231.45l84.97,72.36c4.41,3.81,11.04,3.45,15.01-0.81v0c3.95-4.24,3.85-10.85-0.22-14.97l-78.4-79.56    c-6.28-6.37-16.59-6.22-22.68,0.33C357.81,215.35,358.41,225.65,365.22,231.45z"/>
                    <path className="st1" d="M385.4,478.97c-0.23-5.79-4.98-10.38-10.77-10.4l-111.69-0.48c-8.94-0.04-16.11,7.38-15.76,16.32v0    c0.35,8.94,8.08,15.78,16.99,15.04l111.23-9.26C381.2,489.75,385.62,484.79,385.4,478.97z"/>
                    <path className="st1" d="M453.33,650.98l-80.1,77.85c-6.41,6.23-6.33,16.55,0.17,22.68c6.51,6.14,16.82,5.61,22.66-1.16l72.95-84.47    c3.84-4.38,3.53-11.02-0.71-15.02C464.09,646.89,457.49,646.94,453.33,650.98z"/>
                    <path className="st1" d="M814.79,303c3.97,4.27,10.6,4.63,15.01,0.81l84.97-72.36c6.81-5.8,7.41-16.11,1.32-22.65l0,0    c-6.09-6.55-16.41-6.69-22.68-0.33l-78.4,79.56C810.94,292.16,810.85,298.76,814.79,303L814.79,303z"/>
                    <path className="st1" d="M1017.07,468.09l-111.69,0.48c-5.8,0.02-10.55,4.61-10.77,10.4c-0.23,5.82,4.19,10.78,10.01,11.22    l111.23,9.26c8.91,0.74,16.65-6.1,16.99-15.04v0C1033.18,475.47,1026.01,468.05,1017.07,468.09z"/>
                    <path className="st1" d="M826.67,650.98c-4.16-4.04-10.76-4.09-14.98-0.11c-4.24,4-4.55,10.64-0.71,15.02l72.95,84.47    c5.85,6.77,16.16,7.3,22.66,1.16c6.5-6.14,6.58-16.45,0.17-22.68L826.67,650.98z"/>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default CreditLogoIcon;
