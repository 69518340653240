import React from 'react';
import './ContactBar.css';
import NavLogo from './../../assets/images/NavLogo.webp';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';

const ContactBar = () => {
  return (
    <>
      <div className="contactbar">
        <div className="col-wrapper">
          {/* Navigation Logo */}
          <div className="col-item navLogo">
            <div className="image-wrapper">
              <Link to='/'>
                <img src={NavLogo} alt="Watkins Seamless Gutters Logo" width={473} height={238} />
              </Link>
            </div>
          </div>

          {/* Social Icons */}
          <div className="col-item row-stack">
            <div className="socials-row">
              <div className="socials-wrapper">
                <a href="https://www.facebook.com/GuttersByWatkins/" className="social-icon facebook" aria-label="Visit Watkins Seamless Gutters on Facebook" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-facebook-f" />
                </a>
                <a href="https://goo.gl/maps/i9dm81CxUhcgBvuf8" className="social-icon google" aria-label="Find Watkins Seamless Gutters on Google Maps" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-google" />
                </a>
                <a href="https://www.yelp.com/biz/watkins-seamless-gutters-summerfield-2" className="social-icon yelp" aria-label="Read reviews for Watkins Seamless Gutters on Yelp" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-yelp" />
                </a>
              </div>
            </div>





            {/* Email Section */}
            <p className="email">
              <span>Email:</span>
              <br />
              <a href="mailto:GuttersByWatkins@gmail.com" aria-label="Send an email to Gutters By Watkins">
                GuttersByWatkins@gmail.com
              </a>
            </p>
          </div>

          {/* Contact Button */}
          <div className="col-item contact-button">
            <a href="tel:352-812-0831" aria-label="Call Watkins Seamless Gutters">
                <span className='button-icon-wrapper'>
                    <i className="fa-solid fa-phone button-icon" />
                </span>
                <span className="button-text">352-812-0831</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactBar;
