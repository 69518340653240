import React from 'react';
import './QuoteCard.css';
import { Link } from 'react-router-dom';

const QuoteCard = ({ image, header, description, buttonLink, imageAlt }) => {
    return (
        <div className='quotecard-wrapper'>
            <div className='quotecard-img-wrapper'>
                <img className='quotecard-image' src={image} alt={imageAlt} />
            </div>
            <div className='quotecard-header-wrapper'>
                <h2>
                    {header}
                </h2>
            </div>
            <div className='quotecard-description-wrapper'>
                <p>
                    {description}
                </p>
            </div>

            <Link className='quotecard-button' to={buttonLink}>
                <span className="quotecard-button-text">Click Here to Learn More</span>
            </Link>
        </div>
    );
};

export default QuoteCard;
