import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavBar.css';

const NavBar = () => {
  return (
    <>
      <nav className="navbar" aria-label="Main navigation">
        <div className="navbar-container">
          <div className="navbar-wrapper">
            <div className="navlink-container">
              <ul className="navlink-list">
                <li className="navlink-wrapper">
                  <NavLink
                    to="/"
                    activeclassname="active"
                    className="navlink"
                    onClick={() => window.scrollTo(0, 0)} // Scroll to top
                  >
                    <span>Home</span>
                  </NavLink>
                </li>
                <li className="navlink-wrapper">
                  <NavLink
                    to="/seamless-gutters"
                    activeclassname="active"
                    className="navlink"
                    onClick={() => window.scrollTo(0, 0)} // Scroll to top
                  >
                    <span>Seamless Gutters</span>
                  </NavLink>
                </li>
                <li className="navlink-wrapper">
                  <NavLink
                    to="/gutter-guards"
                    activeclassname="active"
                    className="navlink"
                    onClick={() => window.scrollTo(0, 0)} // Scroll to top
                  >
                    <span>Gutter Guards</span>
                  </NavLink>
                </li>
                <li className="navlink-wrapper">
                  <NavLink
                    to="/testimonials"
                    activeclassname="active"
                    className="navlink"
                    onClick={() => window.scrollTo(0, 0)} // Scroll to top
                  >
                    <span>Our Reviews</span>
                  </NavLink>
                </li>
                <li className="navlink-wrapper">
                  <NavLink
                    to="/gallery"
                    activeclassname="active"
                    className="navlink"
                    onClick={() => window.scrollTo(0, 0)} // Scroll to top
                  >
                    <span>Gallery</span>
                  </NavLink>
                </li>
                <li className="navlink-wrapper">
                  <NavLink
                    to="/contact"
                    activeclassname="active"
                    className="navlink"
                    onClick={() => window.scrollTo(0, 0)} // Scroll to top
                  >
                    <span>Contact</span>
                  </NavLink>
                </li>
                <li className="navlink-wrapper">
                  <NavLink
                    to="/the-villages"
                    activeclassname="active"
                    className="navlink"
                    onClick={() => window.scrollTo(0, 0)} // Scroll to top
                  >
                    <span>The Villages</span>
                  </NavLink>
                </li>
                <li className="navlink-wrapper">
                  <NavLink
                    to="/service-areas"
                    activeclassname="active"
                    className="navlink"
                    onClick={() => window.scrollTo(0, 0)} // Scroll to top
                  >
                    <span>Service Areas</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
