import React from 'react';
import './PersistentContactButton.css';

const PersistentContactButton = ({ onClick, isModalOpen }) => {
  return (
    <div className={`persistent-button-wrapper ${isModalOpen ? 'modal-open' : 'modal-closed'}`}>
      <div className='persistent-button'>
        <button
          onClick={onClick}
          aria-label="Open contact form"
          tabIndex={isModalOpen ? -1 : 0} // Apply tabindex conditionally based on modal state
        >
          Contact
        </button>
      </div>
    </div>
  );
};

export default PersistentContactButton;
