import React from 'react';
import './CallToAction.css';



const CallToAction = () => {

  return (
    <div className='cta-section-wrapper'>
        <div className='cta-content-wrapper'>
            <div className='cta-content'>
                <h2>Contact us to learn more first-hand and let us give you a free no-obligation quote.</h2>
            </div>
        </div>
    </div>
  );
};

export default CallToAction;
