import React from 'react';
import './MobileContactMenu.css';
import { HashLink } from 'react-router-hash-link'; // Use HashLink for scrolling to specific section

const MobileContactMenu = ({ isMenuOpen, toggleContactMenu }) => {

  // Custom scroll handler to adjust the scroll position
  const scrollWithOffset = (el) => {
    const yOffset = -200; // Adjust this value based on your header height or the extra space to scroll above the element
    const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <div className={`mobile-contact-menu-backdrop ${isMenuOpen ? 'open' : 'closed'}`}>
      <div className='mobile-contact-menu'>
        <div className='mobile-contact-menu-items'>
          <div className='mobile-contact-menu-header'>We'd love to hear from you</div>

          <a
            className='mobile-contact-menu-item'
            href='tel:352-812-0831'
            aria-label="Call Watkins Seamless Gutters"
            tabIndex={isMenuOpen ? 0 : -1} 
          >
            <span><i className="fa-solid fa-phone" />&nbsp;&nbsp;Click to call 352-812-0831</span>
          </a>
          <a
            className='mobile-contact-menu-item'
            href='mailto:GuttersByWatkins@gmail.com'
            aria-label="Email Watkins Seamless Gutters"
            tabIndex={isMenuOpen ? 0 : -1}
          >
            <span><i className="fa-regular fa-envelope" />&nbsp;&nbsp;Click to email GuttersByWatkins@gmail.com</span>
          </a>

          {/* Update Link to HashLink with custom scroll */}
          <HashLink
            className='mobile-contact-menu-item'
            to='/contact#contact-form'
            aria-label="Go to the contact page to send a message to Watkins Seamless Gutters"
            tabIndex={isMenuOpen ? 0 : -1}
            onClick={toggleContactMenu} // Close menu when clicked
            scroll={el => scrollWithOffset(el)} // Use the custom scroll function
          >
            <span><i className="fa-regular fa-message" />&nbsp;&nbsp;Click to send us a message</span>
          </HashLink>
        </div>

        <button
          className='mobile-contact-menu-close'
          onClick={toggleContactMenu}
          aria-label="Close contact menu"
          tabIndex={isMenuOpen ? 0 : -1}
        >
          <i className="fa-solid fa-xmark" />
        </button>
      </div>
    </div>
  );
};

export default MobileContactMenu;
