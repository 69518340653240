import React from 'react';
import './Quote.css';
import QuoteCard from '../QuoteCard/QuoteCard';

import quoteCardImage1 from './../../assets/images/quote1.webp';
import quoteCardImage2 from './../../assets/images/quote2.webp';

const Quote = () => {
  // Declare quoteCard data as constants
  const quoteCard1 = {
    image: quoteCardImage1,
    header: 'Premium 5″ or 6″ Seamless Gutters',
    description: "Your exact seamless gutter needs are custom manufactured on-site to fit your requirements. That means an exact fit with no leaky seams. Only seamless gutters give you worry-free, low maintenance, long life service.",
    buttonLink: '/seamless-gutters',
    imageAlt: 'Worker on a ladder installing seamless gutters on a white residential home, with a bright blue sky and landscaped garden featuring vibrant flowers in the foreground.'
  };

  const quoteCard2 = {
    image: quoteCardImage2,
    header: 'Florida Tested Gutter Guards',
    description: "Florida's unique tree habitat plus Spanish Moss and lots of pollen present challenges that almost ALL gutter guards fail to deal with. Our Florida tested and proven units, however, really work!",
    buttonLink: '/gutter-guards',
    imageAlt: 'A clean, newly installed gutter guard system protecting the edge of a roof with shingles, with a car and trees in the background.'
  };

  return (
    <div className='quote-wrapper'>
        <div className='quote-content-wrapper'>
            <div className='quote-section-title'>
                <h1>Get Your No-Obligation Quote</h1>
            </div>
            <div className='quote-section-content-wrapper'>
                <div className='quotecards-container'>
                    <QuoteCard
                        image={quoteCard1.image}
                        header={quoteCard1.header}
                        description={quoteCard1.description}
                        buttonLink={quoteCard1.buttonLink}
                        imageAlt={quoteCard1.imageAlt}
                    />
                    <QuoteCard
                        image={quoteCard2.image}
                        header={quoteCard2.header}
                        description={quoteCard2.description}
                        buttonLink={quoteCard2.buttonLink}
                        imageAlt={quoteCard2.imageAlt}
                    />
                </div>
            </div>
        </div>
    </div>
  );
};

export default Quote;
