import React from 'react';
import './NotFoundPage.css'; // Import the stylesheet
import { Link } from 'react-router-dom';

const NotFoundPage = () => {

    return (
        <div className='not-found-wrapper'>
            <div className='not-found-container'>
                <div className='not-found'>
                    <div className='four-o-four'>
                        <span className='lean'>4</span>
                        &nbsp;04
                    </div>
                    <div class="not-found-description">
                        The page you are looking for
                        <br/>
                        can not be found
                    </div>
                    <Link to='/' className='not-found-home-button'>Return to Homepage</Link>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;