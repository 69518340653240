import React from 'react';
import './WatkinsDifference.css';



const WatkinsDifference = () => {

  return (
    <div className='seamless-gutters-section-wrapper'>
        <div className='seamless-gutters-content-wrapper'>
            <div className='seamless-gutters-section-header'>
                <h2>The Watkins Difference</h2>
            </div>

            <div className='seamless-gutters-section-content'>
                <p>
                    Our reputation is based on service, safety and quality of each and every job, regardless of how large or how small! We are honest, 100% on time, use only the highest quality sealant and with our seamless gutter machine, we are able to produce on-site custom gutters that exactly fit your project's needs.
                    <br/>
                    <br/>
                    Many, many vendors are able to install gutters, but only a select few have the knowledge, experience, and skills, developed over years of field experience, to do it right. The Watkins Team has over <span style={{color: 'white', fontWeight: 'bold'}}>55 years of accumulated experience</span> in the gutter industry and has closely worked with the top gutter and gutter guard manufacturers to both field test designs and to train installers. There are few with this experience in the industry who have “seen it all” and clearly know how to assure the right approach to solving your specific needs.
                    <br/>
                    <br/>
                    Your home deserves the best protection against water damage you can give it and Gutters By Watkins is the one solution you know you can trust.
                    <br/>
                </p>
            </div>
        </div>
    </div>
  );
};

export default WatkinsDifference;
