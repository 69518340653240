import React from 'react';
import './SeamlessGutters.css';



const SeamlessGutters = () => {

  return (
    <div className='seamless-gutters-section-wrapper'>
        <div className='seamless-gutters-content-wrapper'>
            <div className='seamless-gutters-section-header'>
                <h2>Our Seamless Gutter Story</h2>
            </div>

            <div className='seamless-gutters-section-content'>
                <p>
                    Serving Lake, Marion, and Sumter Counties we are a small, family-owned and operated company started in 1998 with a mission to provide you with excellent workmanship and 100% customer satisfaction from project start to completion.
                    <br/>
                    <br/>
                    We take great pride in the quality, customer service, and prompt, on-time results we provide for each of our customers, regardless of how large or how small the job!
                    <br/>
                    <br/>
                    We use only the highest quality sealant and with our on-site gutter machine, we are able to produce custom made gutters formatted exactly to your dimensional requirements. This results in a leak-free, clean appearance that enhances your property value and provides you with worry-free peace-of-mind for the years ahead.
                    <br/>
                </p>
            </div>
        </div>
    </div>
  );
};

export default SeamlessGutters;
