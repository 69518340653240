import React from 'react';
import './Home.css'; // Import the stylesheet
import { Helmet } from 'react-helmet-async';

import Hero from '../../components/Hero/Hero';
import Quote from '../../components/Quote/Quote';
import SeamlessGutters from '../../components/SeamlessGutters/SeamlessGutters';
import OurHistory from '../../components/OurHistory/OurHistory';
import WatkinsDifference from '../../components/WatkinsDifference/WatkinsDifference';
import TopRated from '../../components/TopRated/TopRated';
import CallToAction from '../../components/CallToAction/CallToAction';


const Home = () => {

    return (

        <>
            <Helmet>
                <title>Gutter Installation | Marion County, FL | Watkins Seamless Gutters</title>
                <meta 
                    name="description" 
                    content="Watkins Seamless Gutters provides expert gutter installation, repair, and guards for Summerfield and Marion County, Florida. Call us today at 352-812-0831."
                />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Gutter Installation | Marion County, FL | Watkins Seamless Gutters" />
                <meta name="twitter:description" content="Watkins Seamless Gutters provides expert gutter installation, repair, and guards for Summerfield and Marion County, Florida. Call us today at 352-812-0831." />

                <meta property="og:description" content="Watkins Seamless Gutters provides expert gutter installation, repair, and guards for Summerfield and Marion County, Florida. Call us today at 352-812-0831." />
                <meta property="og:title" content="Gutter Installation | Marion County, FL | Watkins Seamless Gutters" />

                <link rel="canonical" href="https://www.watkinsgutters.com" />
            </Helmet>

            <div className='page-wrapper'>
                <div className='page-content-container'>
                    <section className='hero'>
                        <Hero/>
                    </section>
                    <section className='quote'>
                        <Quote/>
                    </section>
                    <section className='seamless-gutters'>
                        <SeamlessGutters />
                    </section>
                    <section className='our-history'>
                        <OurHistory />
                    </section>
                    <section className='watkins-difference'>
                        <WatkinsDifference />
                    </section>
                    <section className='top-rated'>
                        <TopRated />
                    </section>
                    <section className='call-to-action'>
                        <CallToAction />
                    </section>
                </div>
            </div>
        </>
    );
};

export default Home;