import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './MobileNavBar.css';
import MobileLogo from './../../assets/images/mobileLogo.jpg';

const MobileNavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 785);

  // Toggle the menu open/closed
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Close the menu when a NavLink is clicked
  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Detect screen size changes to update `isMobile` state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 785);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {/* Placeholder to reserve space for the navbar */}
      <div className="navbar-placeholder"></div>

      <nav className="mobile-navbar" aria-label="Main navigation">
        <div className="mobile-navbar-wrapper">
          <div className="mobile-navbar-container">
            <div className="mobile-navbar-items">
              {/* Hamburger toggle */}
              <div className="hamburger-container">
                <div
                    className="hamburger-toggle"
                    aria-label="Toggle menu"
                    aria-expanded={menuOpen}
                    aria-controls="main-menu"
                    role="button"
                    aria-pressed={menuOpen}
                    onClick={toggleMenu}
                    tabIndex={0}
                >
                    <span className={`line top ${menuOpen ? 'open' : ''}`}></span>
                    <span className={`line middle ${menuOpen ? 'open' : ''}`}></span>
                    <span className={`line bottom ${menuOpen ? 'open' : ''}`}></span>
                </div>
              </div>

              {/* Logo */}
              <div className="mobile-navbar-logo-wrapper">
                <div className="mobile-navbar-logo">
                  <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                    <img src={MobileLogo} alt="Watkins Seamless Gutters" height="238" width="473" />
                  </Link>
                </div>
              </div>

              {/* Phone icon */}
              <div className="mobile-navbar-contact-wrapper">
                <div className="mobile-navbar-phone-container">
                  <Link to="tel:352-812-0831" onClick={closeMenu}>
                    <i className="fa-solid fa-phone" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation menu */}
        <div className={`mobile-navmenu-wrapper ${menuOpen ? 'open' : 'closed'}`}>
          <div className="mobile-navmenu-container">
            <div className="mobile-navmenu">
              <div className="mobile-navmenu-items">
                <div className="mobile-socials-wrapper">
                  <div className="socials-row">
                    <div className="socials-wrapper">
                      <a
                        href="https://www.facebook.com/GuttersByWatkins/"
                        className="social-icon facebook"
                        aria-label="Facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={closeMenu}
                        tabIndex={menuOpen && isMobile ? 0 : -1} // Conditionally tabIndex
                      >
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                      <a
                        href="https://goo.gl/maps/i9dm81CxUhcgBvuf8"
                        className="social-icon google"
                        aria-label="Google"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={closeMenu}
                        tabIndex={menuOpen && isMobile ? 0 : -1} // Conditionally tabIndex
                      >
                        <i className="fa-brands fa-google" />
                      </a>
                      <a
                        href="https://www.yelp.com/biz/watkins-seamless-gutters-summerfield-2"
                        className="social-icon yelp"
                        aria-label="Yelp"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={closeMenu}
                        tabIndex={menuOpen && isMobile ? 0 : -1} // Conditionally tabIndex
                      >
                        <i className="fa-brands fa-yelp" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="mobile-email-wrapper">
                  <p>Email: <span style={{ fontWeight: 'bold' }}>GuttersByWatkins@gmail.com</span></p>
                </div>

                {/* Nav links */}
                <div className="mobile-navmenu-navlinks-wrapper">
                <ul className="mobile-navmenu-navlinks">
                  <li>
                    <NavLink
                      to="/"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span>Home</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/seamless-gutters"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span>Seamless Gutters</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/gutter-guards"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span>Gutter Guards</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/testimonials"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span>Our Reviews</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/gallery"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span>Gallery</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span>Contact</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/the-villages"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span>The Villages</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/service-areas"
                      activeclassname="active"
                      className="navlink"
                      onClick={() => {
                        closeMenu();
                        window.scrollTo(0, 0); // Scroll to top
                      }}
                      tabIndex={menuOpen && isMobile ? 0 : -1}
                    >
                      <span>Service Areas</span>
                    </NavLink>
                  </li>
                </ul>

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Always render the overlay and control visibility with styles */}
        <div className={`overlay ${menuOpen ? 'visible' : ''}`} onClick={toggleMenu}></div>
      </nav>
    </>
  );
};

export default MobileNavBar;
